body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #222222;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}


.container .answer,
.container .additionalinfo,
.container .status,
.container .disclaimer {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 20px;
  border: none;
  outline: none;
  box-sizing: border-box;
}

.container select,
.container button{
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 20px;
  border: none;
  outline: none;
  box-sizing: border-box;
  margin-left: 10px;
  margin-right: 10px;
}

.container textarea {
  width: 100%;
  margin-bottom: 5px;
  padding: 10px;
  border-radius: 20px;
  border: none;
  outline: none;
  box-sizing: border-box;
}

.container select {
  background-color: #eeeeee;
  width: 30%;
}

.container textarea {
  height: 150px;
  resize: none;
  text-align: left;
  background-color: #eeeeee;
}

.container .answer {
  background-color: #eeeeee;
  text-align: left;
}

.container .additionalinfo {
  background-color: #808080;
  text-align: left;
}

.container .status {
  text-align: center;
  margin-top: 20px;
  background-color: orange;
}

.container .disclaimer {
  text-align: center;
  background-color: #adcf90;
  text-align: left;
}
.button-container {
  width: 95%;
  display: flex;
  justify-content: center;
}

.small-button {
  margin-left: 10px; 
  float: right;
  color: green;
  background-color: green;
}

.submit-button {
  background-color: #4CAF50; /* Green */
  color: whitesmoke;
  cursor: pointer;
  width: 30%;
}

.submit-button:disabled {
  background-color: #666666;
  color: whitesmoke;
  cursor: not-allowed;
}

.copy-button {
  background-color: #4CAF50; /* Green */
  color: whitesmoke;
  cursor: pointer;
  margin-top: 20px;
  width: 90%;
}

.copy-button:disabled {
  background-color: #666666;
  color: whitesmoke;
  cursor: not-allowed;
}

.clear-button {
  margin-top: 0px;
  background-color: #c93f09;
  color: whitesmoke;
  width: 30%;
}

.footer {
  width: 100%;
  text-align: left;
  color: grey;;
  padding: 20px;
}
.footer a {
  color: grey;
}


.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%; /* Circular shape */
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
